export default {
    confirmDialog(component, message) {
        console.log('confirm dialog', message, component)
        return component.$bvModal.msgBoxConfirm(message, {
            title: component.$t('confirm'),
            size: 'md',
            buttonSize: 'md',
            okVariant: 'danger',
            okTitle: component.$t('yes'),
            cancelTitle: component.$t('no'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
            })
    },


}