<template>
    <div class="content">
        <h2>{{$t('device_settings')}}</h2>
        <h3 v-if="!user">You are not logged in!</h3>
        <b-button class="mt-3" @click.prevent="addDevice" variant="primary">{{$t('add_device')}}</b-button>
        <DeviceList class="mt-2" :deviceList="deviceList" @editDevice="onEditDevice" @removeDevice="onRemoveDevice" @addDevice="onAddDevice"/>
        <b-modal ref="modal-1" id="modal-1" :title="modalTitle" @ok="handleOk" :ok-only="isEditMode" :ok-title="isEditMode?this.$t('close'):'OK'" no-close-on-backdrop> 
           <EditDevice ref="edit-device" :device="selectedDevice" :action="action"/> 
        </b-modal>
        
    </div>
</template>
<script>
/*
<b-button v-b-modal.modal-1>Add new device</b-button>
<b-modal ref="modal-1" id="modal-1" title="Add Device">
           <EditDevice :device="selectedDevice" :action="action"/> 
        </b-modal>
<form @submit.prevent="handleSubmit">
                <div class="form-group">
                    <label>Name</label>
                    <input type="name" class="form-control" v-model="name" placeholder="Device Name"/>
                </div>
                <b-form-select :options="deviceTypes" name="deviceType">
                </b-form-select>

                <p class="my-4">Sims</p>
                <SimList :simList="simList"/>
                <b-button>Add SIM</b-button>
                <b-button type="submit" variant="primary">Submit</b-button>
            </form>
            */
import {mapGetters} from 'vuex'
//import axios from 'axios'
import DeviceList from './DeviceList.vue'
import EditDevice from './EditDevice.vue'
import dialogs from '../Common/dialogs'
//<h3 v-if="!user">You are not logged in!</h3>
//<h3 v-if="user">{{count}}</h3>
//<DeviceList :deviceList="devices" v-on:deviceSelected="onDeviceSelected"/>
export default {
    name: 'DeviceSetup',
    components: {
        DeviceList,
        EditDevice
    },
    data() {
        return {
            //devices: [],
            selectedDevice: this.newDevice(),
            simList: [],
            action: 'add',
            name:'',
            deviceType: '0',
            /*deviceTypes: [
                { value: null, text: this.$t('select_device_type') },
                { value: '0', text: 'Solar Kerberos 315.B, 315.C, 315.H, 320.B, 320.H' },
                { value: '1', text: 'Kerberos POWER.6000' },
            ]*/

            
        }
    },
    computed: {
        ...mapGetters(['user']),
        ...mapGetters(['deviceList']),
        count(){
            return this.devices.length
        },
        modalTitle(){
            return (this.action == 'add')?this.$t('add_device'):this.$t('edit_device')
        },
        isEditMode(){
            return (this.action != 'add')
        }
    },
    methods: {
        newDevice() {
            return {
                name:'',
                deviceType: 0,
                sims:[],
            }
        },
        showModal() {
            this.$refs['modal-1'].show()
        },
        hideModal() {
            this.$refs['modal-1'].hide()
        },
        handleOk(bvModalEvt){
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.$refs['edit-device'].handleSubmit().then((success)=>{
                if (success) {
                    this.$nextTick(() => {
                        this.hideModal()
                    })
                }
            }) 
        },
        addDevice(){
            this.action = 'add'
            this.selectedDevice = this.newDevice()
            this.$store.dispatch("clear_sim_list")
            this.showModal()
        },
        onAddDevice: function () {
            this.addDevice()
        },
        onEditDevice: function (id) {
            console.log(id)
            var device = this.deviceList.find(obj => obj.id == id)
            if (device) {
                this.selectedDevice = device
                this.action = 'edit'
                this.$store.dispatch("get_sim_list", id)
                .then(success => {
                    console.log(success)
                    this.showModal()
                })
                .catch(error => {
                    console.log(error)
                    alert(error)
                })
                this.showModal()
            }
        },
        onRemoveDevice: function (id) {
            dialogs.confirmDialog(this, this.$t('device_delete_confirm'))
            .then(value => {
                if (value){
                    console.log('Remove device ' + id)
                    if (id) {
                        this.$store.dispatch("delete_device", id)
                        .then(success => {
                            console.log("delete_device", id, success)
                            //alert(this.$t('device_deleted'))
                        })
                        .catch(error => {
                            console.log(error)
                            alert(error)
                        })
                    }
                }
            })
            .catch(err => {
                console.log(err)
                // An error occurred
            })
            
        },
        /*handleSubmit() {
            this.$store.dispatch("create_device", {
                name: this.name,
                deviceType: this.deviceType
            })
            .then(success => {
                console.log(success)
                this.getDevices()
                alert(this.$t('device_created'))
                //this.$router.push("/")
            })
            .catch(error => {
                console.log(error)
                alert(error)
            })
        },*/
        getDevices(){
            this.$store.dispatch("get_device_list")
            .then(success => {
                console.log('loaded device data', success)
                //this.$router.push("/")
                //this.$store.dispatch('set_user', {email:'',logged:true})
            })
            .catch(error => {
                console.log('failed to load device data', error)
                //this.$router.push('/login')
                //this.error = true;
            })
        }
    },
    async created() {
            this.getDevices()
        }
}
</script>
