<template>
    <div>
        <!--<p v-if="count==0" class="mt-4">{{$t('no_devices_in_list')}}</p>-->
        <b-list-group>
            <b-list-group-item v-for="device in deviceList" :key="device.id" >
                <div class="clearfix">
                    <div class="action-block-col">{{device.name}}</div>
                    <div class="action-block-col float-right">
                        <b-button-group >
                            <b-button size="sm" variant="light" @click.prevent="editDevice(device.id)">
                                <b-icon icon="pencil" :title="$t('edit')"></b-icon>
                                <span class="visible-lg-inline"> {{$t('edit')}} </span>
                            </b-button>
                            <b-button size="sm" variant="light" @click.prevent="removeDevice(device.id)">
                                <b-icon icon="trash-fill" aria-hidden="true" :title="$t('delete')"></b-icon>
                                <span class="visible-lg-inline"> {{$t('delete')}}</span>
                            </b-button>
                            
                        </b-button-group>
                    </div>
                </div>
            </b-list-group-item>
            <b-list-group-item v-if="count==0" >
                <div class="clearfix">
                    <div class="">{{$t('no_devices_in_list')}} <b-button class="" size="sm" @click.prevent="addDevice" variant="light">{{$t('add_device')}}</b-button></div>
                </div>
            </b-list-group-item>
        </b-list-group>
    </div>    
</template>

<script>
/*      <b-dropdown size="sm" variant="light" right text="Split Menu">
                                <template #button-content>
                                    <b-icon icon="three-dots-vertical" title="Actions"/>
                                </template>
                                <b-dropdown-item>Item 1</b-dropdown-item>
                                <b-dropdown-item>Item 2</b-dropdown-item>
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item>Item 3</b-dropdown-item>
                            </b-dropdown>*/
                            
//        <b-form-select id="device" v-model="selected" :options="deviceList"></b-form-select>

export default {
    name: 'DeviceList',
    data() {
      return {
      }
    },
    props: ['deviceList'],
    computed: {
        count(){
            return this.deviceList?.length
        }
    },
    methods: {
        editDevice(id) {
            console.log('edit device',id)
            //console.log(event.target.value)
            this.$emit('editDevice',id)
        },
        removeDevice(id){
            console.log('remove device',id)
            this.$emit('removeDevice',id)
        },
        addDevice(){
            console.log('add device')
            this.$emit('addDevice')
        }
        /*onChange(event) {
            console.log(event)
            //console.log(event.target.value)
            this.$emit('deviceSelected',event)
        }*/
    }    
}
</script>
<style>
  .action-block-col {
    display: inline-block;
  }
</style>