<template>
    <div>
        <b-list-group>
            <b-list-group-item v-for="sim in simList" :key="sim.id" >
                <div class="clearfix">
                    <div class="action-block-col">{{sim.number}}</div>
                    <div class="action-block-col float-right">
                        <b-button-group >
                            <b-button size="sm" variant="light" @click.prevent="removeSim(sim.id, sim.number)">
                                <b-icon icon="trash-fill" aria-hidden="true" :title="$t('delete')"></b-icon>
                                <span class="visible-lg-inline"> {{$t('delete')}}</span>
                            </b-button>
                        </b-button-group>
                    </div>
                </div>
            </b-list-group-item>
        </b-list-group>
    </div>    
</template>

<script>
//        <b-form-select id="device" v-model="selected" :options="deviceList"></b-form-select>
import dialogs from '../Common/dialogs'

export default {
    name: 'SimList',
    data() {
      return {
      }
    },
    props: ['simList'],
    methods: {
        /*onChange(event) {
            console.log(event)
            //console.log(event.target.value)
            this.$emit('deviceSelected',event)
        }*/
        removeSim(id, number){
            console.log('remove sim',id)
            //this.$emit('removeSim',id)
            //console.log(id)
            //this.$data.selectedDevice = id
            dialogs.confirmDialog(this, this.$t('sim_delete_confirm'))
            .then(value => {
                if (value){
                    if (id) {
                        this.$store.dispatch("delete_sim", id)
                        .then(success => {
                            console.log("delete_sim", id, success)
                            //alert(this.$t('sim_deleted'))
                        })
                        .catch(error => {
                            console.log(error)
                            alert(error)
                            //this.error = 'Invalid username/password!'
                        })
                    } else {
                        this.$store.dispatch("delete_sim_local", number)
                    }
                }
            })
            .catch(err => {
                console.log(err)
                // An error occurred
            })
        }
    }    
}
</script>
<style>
  .action-block-col {
    display: inline-block;
  }
</style>