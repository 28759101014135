<template>
    <div>
        <form ref="form" @submit.prevent="handleSubmit">
            <b-form-group
                :label="$t('device_name')"
                label-for="name-input"
                :invalid-feedback="nameFeedback"
                :state="nameState"
                >
            
                <b-form-input type="text" class="form-control" v-model="device.name" ref="name-input" 
                    :placeholder="this.$t('device_name')" required
                    :disabled="isEditMode"
                    />
            </b-form-group>
            <div class="form-group">
                <label>{{$t('device_type')}}</label>
                <b-form-select 
                    :options="deviceTypes" 
                    name="deviceType" 
                    v-model="device.deviceType"
                    :disabled="isEditMode" 
                    >
                </b-form-select>
            </div>

            <div class="my-4" v-if="isEditMode">
                <div class="d-flex align-middle ">
                            <div class="d-inline-flex mt-1"><h5>{{$t('sim_list')}}</h5></div>
                            <div class="d-inline-flex mb-1"> 
                                <b-button v-b-modal.modal-sim size="sm" variant="none" >
                                    <b-icon icon="question-circle" aria-label="Help"></b-icon>
                                </b-button>
                            </div>
                    </div>
                <SimList :simList="simList"/>
                <b-input-group class="mt-3">
                    <b-form-input type="text" class="form-control" v-model="newsim"
                        id="input-newsim" 
                        :placeholder="this.$t('enter_sim_number')"
                        :state="simState"
                        :invalid-feedback="simFeedback"
                        />
                    <b-input-group-append>
                        <b-button @click.prevent="addSim" variant="primary">{{$t('add_sim')}}</b-button>
                    </b-input-group-append>
                    <b-form-invalid-feedback id="input-newsim-feedback">
                    {{simFeedback}}
                    </b-form-invalid-feedback>
                </b-input-group>
            </div>
            <b-form-group v-if="!isEditMode"
                
                label-for="sim-input"
                :invalid-feedback="simFeedback"
                :state="simState"
                >
                <template slot="label">
                    <div class="d-flex align-baseline ">
                            <div class="d-inline-flex mt-1">{{$t('sim_number')}}</div>
                            <div class="d-inline-flex"> <b-button v-b-modal.modal-sim size="sm" variant="none" >
                            <b-icon icon="question-circle" aria-label="Help"></b-icon>
                            </b-button></div>
                    </div>
                    
                </template>
                
                <b-form-input type="text" class="form-control" v-model="newsim" :placeholder="this.$t('enter_sim_number')" required/>
            </b-form-group>
            
            <b-modal id="modal-sim" :title="$t('sim_number') + ' (IccID)'" ok-only :ok-title="this.$t('close')">
                <p >{{$t('sim_number_help')}}</p>
                <div class="d-flex justify-content-center">
                <img src="SIM1_Iccid.png"/>
                </div>
            </b-modal>
            
        </form>
    </div>
</template>
<script>
/*:label="$t('sim_number')"
<h3 v-if="action == 'add'">Add Device</h3>
  <h3 v-else>Edit Device</h3>
<b-button type="submit" variant="primary">Submit</b-button>*/
//<h3 v-if="!user">You are not logged in!</h3>
//<h3 v-if="user">{{count}}</h3>
//<DeviceList :deviceList="devices" v-on:deviceSelected="onDeviceSelected"/>

/*
const getLuhnIccdId = (iccid, hasCheckSum = false)=> {
  // reverse iccd and transform each item as integer
  const iccidArray = iccid.split('').reverse().map(x => parseInt(x))

  // if checksum digit was given, remove it from the array, otherwise make it zero
  const givernCheckSum = hasCheckSum ? iccidArray.splice(0, 1)[0] : 0
  
  // Luhn algorithm.
  const sum = iccidArray
    // double the value of every second digit
    .map((value, index) => ( (index % 2 == 0) ? 
                          ( value == 9 ? value : ( (value * 2) % 9 ) ) : ( value ) ) )
    // compute the sum of all digits
    .reduce((acc, value) => (acc + value))
  
  // compute the checksum 
  const calculatedCheckSum =  parseInt( (sum * 9) % 10 )

  // if checkSum has been passed, return true or false to compare it, otherwise return the calculatedCheckSum
  return hasCheckSum ? ( calculatedCheckSum === givernCheckSum  ? true : false ) : calculatedCheckSum
}
*/

import {mapGetters} from 'vuex'
import SimList from './SimList.vue'
export default {
    name: 'EditDevice',
    components: {
        SimList
    },
    data() {
        return {
            newsim: '',
            selectedDevice: null,
            name:'',
            nameState: null,
            nameFeedback: '',
            simState: null,
            simFeedback: '',
            deviceType: '0',
            deviceTypes: [
                { value: null, text: this.$t('select_device_type'), disabled: true },
                { value: '0', text: 'Solar Kerberos 315.B, 315.C, 315.H, 320.B, 320.H' },
                { value: '1', text: 'Kerberos POWER.6000' },
            ]

            
        }
    },
    props: [
        'device',
        'action'
        ],
    computed: {
        ...mapGetters(['user']),
        ...mapGetters(['deviceList', 'simList']),
        count(){
            return this.devices.length
        },
        isEditMode(){
            return this.action!='add'
        }
    },
    watch: {
        newsim: function() {
            this.checkSimValidity()
        },
        device: {
            handler(){
                this.checkNameValidity()
            },
            deep: true
        }
    },
    methods: {
        
        checkSimValidity() {
            //https://www.smartjac.biz/index.php/privacy_policy/84-articles/96-iccid-checksum
            //https://www.justinsilver.com/technology/sim-card-iccid-validation/
            var valid = true
            this.newsim = this.newsim.trim()
            var simRegex = /^[0-9]{19}$/;
            valid = simRegex.test(this.newsim);
            if (!valid)
                this.simFeedback = this.$t('sim_number_required')
            /*else {
                valid = getLuhnIccdId(this.newsim, true)
                if (!valid)
                    this.simFeedback = this.$t('sim_number_bad_format')
            } */   
            this.simState = valid
            return valid
        },
        checkNameValidity(){
            var valid = true
            this.device.name = this.device.name.trim()
            if (this.device.name == '') {
                this.nameFeedback = this.$t('device_name_required')
                valid = false
            } 
            this.nameState = valid
            return valid
        },
        checkFormValidity() {
            //var valid = this.$refs.form.checkValidity()
            var valid = this.checkNameValidity()
            if (this.action == 'add'){
                // check sim if add mode
                valid &= this.checkSimValidity() 
            }
            return valid
        },
        handleSubmit() {
            return new Promise((resolve, reject) => {
                if (!this.checkFormValidity()) {
                    resolve(false)
                    return false
                }
                //var self = this
                
                var payload = {
                    name: this.device.name,
                    deviceType: this.device.deviceType
                }
                if (!this.isEditMode) {
                    var method = 'create_device'
                } else {
                    method = 'update_device'
                    payload.id = this.device.id
                    console.log('Cannot update device')
                    resolve(true)
                    return true
                }
                this.nameState = null
                // create device (update is not supported by server)
                console.log('device submit payload', payload, method)
                this.$store.dispatch(method, payload)
                .then(data => {
                    console.log(data)
                    if (!this.isEditMode) {
                        // tady ulozit simky a vyresit errory
                        if (data.id != null)
                            this.$store.dispatch("add_sim", {
                                deviceId: data.id,
                                number: this.newsim.trim()
                            })
                            .then(success => {
                                var result = success
                                console.log('addsim result',result)
                                if (!result) {
                                    alert(this.$t('save_sim_failed'))
                                }
                                resolve(result)
                                
                            })
                            .catch(error => {
                                console.log('addsim error',error)
                                alert(error)
                                reject(error)
                            })
                    } else {
                        resolve(true)
                        //alert(this.$t('device_added'))
                    }
                })
                .catch(error => {
                    console.log(error)
                    alert(error)
                    reject(error)
                    //this.error = 'Invalid username/password!'
                })
            })
        },
        addSim() {
            return new Promise((resolve, reject) => {
                if (!this.checkSimValidity()) {
                    resolve(false)
                    return false
                }
                if (!this.newsim) {
                    console.log('newsim is empty!')
                    resolve(false)
                    return false
                } 
                else 
                if (this.device.id) {
                    this.$store.dispatch("add_sim", {
                        deviceId: this.device.id,
                        number: this.newsim.trim()
                    })
                    .then(success => {
                        console.log(success)
                        //alert(this.$t('sim_added'))
                        var result = success
                        // clear new sim input
                        this.newsim = ''
                        this.simState = null
                        this.$store.dispatch("get_sim_list", this.device.id)
                        .then(success => {
                            console.log(success)
                            resolve(success && result)
                            this.simState = null
                        })
                        .catch(error => {
                            console.log(error)
                            alert(error)
                            reject(error)
                        })
                    })
                    .catch(error => {
                        console.log(error)
                        alert(error)
                        reject(error)
                        //this.error = 'Invalid username/password!'
                    })
                } else {
                    if (this.action == 'add') {
                        this.$store.dispatch("add_sim_local", {
                            deviceId: null,
                            number: this.newsim.trim(),
                            id: null
                        })
                    } else {
                        alert(this.$t('add_sim_failed'))
                    }
                }
            })
        },
        /*onDeviceSelected: function (id) {
            console.log(id)
            //this.$data.selectedDevice = id
            this.loadDeviceDetails(id)
        },
        async loadDeviceDetails(id){
            try {
                const response = await axios.get('Sensor/DataByDeviceId/'+id)
                console.log(response);
                this.$data.selectedDevice = id
                this.$data.sensorData = response.data
                console.log('sensorData:', response.data)
                //localStorage.setItem('token', response.data.token)
                //this.$store.dispatch('user', {email:'',logged:true})
                //this.$router.push('/')
            } catch (e) {
                this.$router.push('/login')
                //this.error = 'Invalid username/password!'
            }

        }*/
        getDevices(){
            this.$store.dispatch("get_device_list")
            .then(success => {
                console.log('loaded device data', success)
                //this.$router.push("/")
                //this.$store.dispatch('set_user', {email:'',logged:true})
            })
            .catch(error => {
                console.log('failed to load device data', error)
                this.$router.push('/login')
                //this.error = true;
            })
        }
    },
    async created() {
            this.getDevices()
            
        }
}
</script>
